<template>
<div class="newsMore" id="dynamic">
  <div class="newsMain">
  <div class="newsLeft">
    <div class="greenOne"></div>
    <div class="navMain">
      <div class="navEver" :class="{'navChecked':(navPk=='dynamic')}" @click="navChange('dynamic')">
        <div class="navCir"></div>
        <div class="navName">公司动态</div>
      </div>

      <div class="fakerLine"></div>

      <div class="navEver" :class="{'navChecked':(navPk=='realInfo')}" @click="navChange('realInfo')">
        <div class="navCir"></div>
        <div class="navName">行业资讯</div>
      </div>

      <div class="fakerLine"></div>

      <div class="navEver" :class="{'navChecked':(navPk=='mediaCan')}" @click="navChange('mediaCan')">
        <div class="navCir"></div>
        <div class="navName">媒体频道</div>
      </div>
    </div>
    <div class="greenTwo"></div>
  </div>
    <div class="newsRight">
      <div class="newsTitle">{{newsTitle}} /<span>news</span></div>
      <div class="newsFrame">
        <div class="newsCar">
         <el-carousel indicator-position="none">
           <el-carousel-item v-for="(item,index) in imgCar" :key="index">
             <img :src="item">
           </el-carousel-item>
         </el-carousel>
        </div>
        <div class="newsList">
          <div class="newsEver" v-for="(item,index) in nowNewsData" :key="index">
         <div class="everLeft">
           <div class="leftTop">{{item.date.substr(8)}}</div>
           <div class="leftBom">
             {{item.date.substr(0,7)}}
           </div>
         </div>
          <div class="everRight">
            <div class="lineOne">{{item.newsTitle}}</div>
            <div class="lineTwo" v-html="item.newsContent"></div>
            <div class="lineThree" @click="toNewsPage(index)">查看详情<i class="el-icon-caret-right"></i></div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "newsMore",
  data(){
    return{
      navPk:"dynamic",
      imgCar:[],
      navData:[
        {label:"公司动态",value:"dynamic"},
        {label:"行业资讯",value:"realInfo"},
        {
          label: "媒体频道",value: "mediaCan"
        }
      ],
      nowNewsData:[],
      newsData:{}
    }
  },
  computed:{
    newsTitle(){
      switch (this.navPk){
        case "dynamic":
          return "公司动态";
          case "realInfo":
            return "行业资讯";
            case "mediaCan":
              return "媒体频道"
      }
    }
  },
  methods:{
    navChange(option){
      let allNewsData=this.$store.state.newsData;
      this.$store.state.newsType=option;
      this.navPk=option;
      switch (this.navPk){
        case "dynamic":
          this.nowNewsData=allNewsData.dynamic;
          break;
        case "realInfo":
          this.nowNewsData=allNewsData.realInfo;
          break;
        case "mediaCan":
          this.nowNewsData=allNewsData.mediaCan;
          break;
      }
      this.imgCar=[];
      this.nowNewsData.forEach((item,index)=>{
        this.imgCar.push(item.imgPath);
      })
    },
    newsInit(){
      this.nType=this.$store.state.newsType;
      this.nData=this.$store.state.newsData;
      this.nIndex=this.$store.state.newsIndex;
      switch (this.nType){
        case "dynamic":
          this.nowNewsData=this.nData.dynamic;
          break;
        case "realInfo":
          this.nowNewsData=this.nData.realInfo;
          break;
        case "mediaCan":
          this.nowNewsData=this.nData.mediaCan;

      }
      this.nowNewsData.forEach((item,index)=>{
        this.imgCar.push(item.imgPath);
      })
    },
    toNewsPage(option) {
      this.$store.state.newsIndex=option;
      this.$router.push("newsPage")
    }
  },
  mounted() {
    this.newsInit();
  }
}
</script>

<style lang="scss" scoped>
.newsMore{
  position: relative;
  width: 1920px;
  height: 600px;

}
.newsMain{
  width: 1200px;
  height: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

}
.newsLeft{
  width: 210px;
  height: 600px;

}
.greenOne{
 width: 210px;
  height: 20px;
  background: #A7C69C;
}
.greenTwo{
  width: 210px;
  height: 100px;
  background: #A7C69C;
}
.navMain{
  width: 210px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navEver{
  position: relative;
  width: 210px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
  cursor: pointer;
  .navCir{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #EEAA3D;
    margin-right: 20px;
  }
  .navName{
    color: #666666;
    font-size: 20px;
  }

}
.fakerLine{
  background: #707070;
  width: 210px;
  height: 1px;
}
.newsRight{
  width: 980px;
  height: 600px;
}
.navChecked{
  height: 100px !important;
  transition: 0.5s;
}
.newsTitle{
  color: #333333;
  font-size: 36px;
  width: 100%;
  line-height: 50px;
  border-bottom: 2px solid #999999;
  margin-top: 20px;
  span{
    font-size: 28px;
  }
  margin-bottom: 20px;
}
.newsFrame{
  width: 980px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.newsCar{
  position: relative;
  width: 250px;
  height: 370px;
  border-radius: 10px;
  overflow: hidden;
  /deep/ .el-carousel__container{
    width: 250px;
    height: 370px;
  }
  /deep/ .el-carousel__item{
    width: 250px;
    height: 370px;
  }
  img{
    height: 100%;
  }
}
.newsList{
  position: relative;
  width: 700px;
}
.newsEver{
  width: 700px;
  display: flex;
  border-bottom: 2px  solid lightgray;
  margin-bottom: 10px;
  .everLeft{
   width:70px;
    height: 80px;
    margin-right: 15px;
    .leftTop{
      font-size: 36px;
      color: #666666;
      text-align: center;
      width: 100%;
      border-bottom: 1px solid #666666;
    }
    .leftBom{
      font-size: 16px;
      color: #666666;
      text-align: center;
      line-height: 35px;
    }
  }
  .everRight{
    //padding-top: 0.07rem;
    box-sizing: border-box;
    width: 580px;
   .lineOne{
     width: 580px;
     color: #333333;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp:1;
     overflow: hidden;
     font-size: 16px;
     font-weight: 500;
   }
    .lineTwo{
      width: 580px;

      color: #666666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp:1;
      overflow: hidden;
      font-size: 16px;
      margin-top: 3px;
    }
    .lineThree{
      width: 580px;

      color: #666666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp:1;
      overflow: hidden;
      font-size: 16px;
      margin-top: 3px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>
