<template>
<div class="fanticNews" id="information">
<div class="fanticTitle">精选资讯</div>
  <div class="fanticList">
    <div class="newsEver" @click="toOuterPage('https://www.baicaolu.net/answer/1397.html')">
      <img src="../../assets/newImgs/newCenter/fantic1.jpg">
      <div class="cover">吃苹果的好处</div>
    </div>
    <div class="newsEver" @click="toOuterPage('https://www.baicaolu.net/question/1lb5vzM9.html')">
      <img src="../../assets/newImgs/newCenter/fantic2.jpg">
      <div class="cover">平衡膳食</div>
    </div>
    <div class="newsEver" @click="toOuterPage('https://www.120v.cn/medical/final/426uks0jc.html')">
      <img src="../../assets/newImgs/newCenter/fantic3.jpg">
      <div class="cover">多吃蔬菜</div>
    </div>
    <div class="newsEver" @click="toOuterPage('https://www.baicaolu.net/answer/1004.html')">
      <img src="../../assets/newImgs/newCenter/fantic4.jpg">
      <div class="cover">西兰花营养价值</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "fanticNews",
  data(){
    return{

    }
  },
  methods:{
    toOuterPage(url){
      let a=document.createElement("a");
      a.setAttribute('href',url);
      a.setAttribute('target',"_blank")
      a.click();
    }
  }
}
</script>

<style lang="scss" scoped>
.fanticNews{
  position: relative;
  width: 1920px;
  height: 600px;
  padding-top: 80px;
  box-sizing: border-box;
}
.fanticTitle{
  width: 100%;
  text-align: center;
  color: #666666;
  font-size: 36px;
}
.fanticList{
  width: 1200px;
  height:270px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 100px;
  .newsEver{
    position: relative;
   width: 180px;
    height: 270px;
    border-radius: 10px;
    background: #E3E3E3;
    overflow: hidden;
    cursor: pointer;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .newsEver:hover .cover{
    transform: translateY(-270px);
    transition: 1s;
  }
  .cover{
    position:absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 270px;
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    color: white;
    transition: 1s;
    line-height: 450px;
  }
  .cover:hover{
    transform: translateY(-270px);
    transition: 1s;
  }
}
</style>
