<template>
<div class="newsCenter" >
<gt-header checked="xwzx"></gt-header>
  <banner></banner>
  <news-more></news-more>
  <fantic-news></fantic-news>
  <gt-footer></gt-footer>
</div>
</template>

<script>
import GtHeader from "../../components/gtHeader";
import GtFooter from "../../components/gtFooter";
import Banner from "../../components/newsCenter/banner";
import NewsMore from "../../components/newsCenter/newsMore";
import FanticNews from "../../components/newsCenter/fanticNews";
export default {
  name: "newsCenter",
  components: {FanticNews, NewsMore, Banner, GtFooter, GtHeader}
}
</script>

<style lang="scss" scoped>
.newsCenter{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width:10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb{
    border-radius:5px;
    background:rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track{
    border-radius:0px;
    height:100px;
    background:rgba(167, 198, 156,0.3);
  }
}
</style>
