<template>
<div class="banner">
  <div class="bannerTitle">
   <div class="lineOne">新闻中心</div>
    <div class="lineTwo">News Center</div>
  </div>
</div>
</template>

<script>
export default {
  name: "banner"
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  width: 1920px;
  height: 440px;
  background: url("../../assets/newImgs/newCenter/pic.png");
  background-size: 100% 100%;
}
.bannerTitle{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  box-sizing: border-box;
}
.lineOne{
  font-size: 50px;
  color: white;
}
.lineTwo{
  font-size: 30px;
  color: white;
}
</style>
